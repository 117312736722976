// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css #ffffff*/

/* Синий фон для всей страницы */
body {
    background-color: #0e0f46;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Блок с полями ввода в центре страницы */
#change-password-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 5px
}

/* Границы и скругленные углы для блока с полями */
#change-password-form .MuiPaper-root {
    border: 1px solid #ccc;
    border-radius: 8px;
}

/* Границы и скругленные углы для блока с полями */
#change-password-form .css-t039ty-MuiButtonBase-root-MuiButton-root-RaSaveButton-root.Mui-disabled {
    color: #ffffff;
    box-shadow: none;
    background-color: rgb(80, 117, 222);
}

/* Стилизация текстовых полей, сохраняя цвет фона */
#change-password-form input[type="text"],
#change-password-form input[type="password"] {
    background-color: #fff; /* Цвет фона полей */
    color: #000; /* Цвет текста в полях */
}

.MuiButtonBase-root {
    background-color: #0e0f46;
}

`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAAA,sBAAsB;;AAEtB,gCAAgC;AAChC;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,wBAAwB;IACxB,SAAS;AACb;;AAEA,0CAA0C;AAC1C;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAyB;IACzB;AACJ;;AAEA,kDAAkD;AAClD;IACI,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA,kDAAkD;AAClD;IACI,cAAc;IACd,gBAAgB;IAChB,mCAAmC;AACvC;;AAEA,mDAAmD;AACnD;;IAEI,sBAAsB,EAAE,oBAAoB;IAC5C,WAAW,EAAE,wBAAwB;AACzC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["/* styles.css #ffffff*/\n\n/* Синий фон для всей страницы */\nbody {\n    background-color: #0e0f46;\n}\n\ninput[type=number]::-webkit-inner-spin-button,\ninput[type=number]::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n}\n\n/* Блок с полями ввода в центре страницы */\n#change-password-form {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: #ffffff;\n    border-radius: 5px\n}\n\n/* Границы и скругленные углы для блока с полями */\n#change-password-form .MuiPaper-root {\n    border: 1px solid #ccc;\n    border-radius: 8px;\n}\n\n/* Границы и скругленные углы для блока с полями */\n#change-password-form .css-t039ty-MuiButtonBase-root-MuiButton-root-RaSaveButton-root.Mui-disabled {\n    color: #ffffff;\n    box-shadow: none;\n    background-color: rgb(80, 117, 222);\n}\n\n/* Стилизация текстовых полей, сохраняя цвет фона */\n#change-password-form input[type=\"text\"],\n#change-password-form input[type=\"password\"] {\n    background-color: #fff; /* Цвет фона полей */\n    color: #000; /* Цвет текста в полях */\n}\n\n.MuiButtonBase-root {\n    background-color: #0e0f46;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
